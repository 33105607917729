"use strict";

(function () {
  "use strict";

  /* @ngdoc object
   * @name customers
   * @description
   *
   */

  angular.module("depa", ["ui.router", "common", "angularMoment", "depa.choiceSlot"]);
})();