"use strict";

(function () {
  "use strict";

  Ctrl.$inject = ["vm", "UsersService", "GroupsService", "moment"];
  angular.module("depa").controller("DepasCtrl.export", Ctrl);

  function Ctrl(vm, UsersService, GroupsService, moment) {
    vm.export = function () {
      console.log("in export");

      var data = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = vm.rawData[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var depa = _step.value;

          try {
            if (depa.driver && depa.driver.defaultManagerId) {
              depa.manager = UsersService.getById(depa.driver.defaultManagerId);
            }

            var row = {
              "Sinistre Référence": depa.damageName,
              "Sinistre Date": depa.dateDamage ? moment(depa.dateDamage).format("DD/MM/YYYY") : "",
              "Nombre de jours écoulés": depa.nbDays,
              "Groupe du collaborateur": depa.groupName,
              "Nom Collaborateur": depa.driverName,
              "Matricule Collaborateur": depa.driver.internalNumber,
              "Téléphone à utiliser pour appeler le collaborateur": depa.phone,
              "Téléphone à utiliser pour envoyer le lien de prise de RDV": depa.phoneToUse,
              "Email à utiliser pour envoyer le lien de prise de RDV": depa.emailToUse,
              "Date relance 1": depa.emailReminderDate1 ? moment(depa.emailReminderDate1).format("DD/MM/YYYY") : "",
              "Date relance 2": depa.emailReminderDate2 ? moment(depa.emailReminderDate2).format("DD/MM/YYYY") : "",
              "Date relance 3": depa.emailReminderDate3 ? moment(depa.emailReminderDate3).format("DD/MM/YYYY") : "",
              "Date 1er export final": depa.emailReminderDateLast ? moment(depa.emailReminderDateLast).format("DD/MM/YYYY") : "",
              Statut: depa.status,
              "Date du RDV": depa.slot ? moment(depa.slot.start).format("DD/MM/YYYY") : "",
              "Heure du RDV": depa.slot ? moment(depa.slot.start).format("HH:mm") : "",
              "Conseiller/ère": depa.operator ? depa.operator.firstName + " " + depa.operator.lastName : "",
              "Date de l'acte managérial": depa.managerActDate ? moment(depa.managerActDate).format("DD/MM/YYYY") : "",
              "Rapport DEPA signé": depa.managerActSigned ? "OUI" : "",
              "Commentaire du manageur": depa.managerActComment,
              "Type de sinistre": depa.typeOfAccidentName ? depa.typeOfAccidentName : "",
              Circonstance: depa.circumstance,
              "Mots clés": depa.keywords ? depa.keywords.join(", ") : "",
              Evitabilité: depa.evitability,
              "Evitable pour le conducteur ?": depa.isEvitable,
              "Evitable pour LVR ?": depa.isEvitableLvr,
              "Conseils LVR": depa.advices
            };

            row["Nom " + vm.defaultManagerLabel] = depa.managerName;
            row["Email " + vm.defaultManagerLabel] = depa.managerEmail;

            data.push(row);
          } catch (error) {
            console.log("depa export error", depa, error);
          }
        }

        /* generate a worksheet */
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var ws = XLSX.utils.json_to_sheet(data);

      var columnsWidth = [];

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = Object.keys(data[0])[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var key = _step2.value;

          columnsWidth.push({ wch: key.length + 2 });
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      ws["!cols"] = columnsWidth;

      /* add to workbook */
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Depas");

      /* write workbook (use type 'binary') */
      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      /* generate a download */
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) {
          view[i] = s.charCodeAt(i) & 0xff;
        }return buf;
      }

      saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), "export depas.xlsx");
    };
  }
})();