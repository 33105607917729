"use strict";

angular.module("models").service("CoursesService", ["LbService", function (LbService) {
  var that = this;

  var params = {
    name: "courses",
    attributesForCache: ["name"],
    formatName: function formatName(element) {
      return element.name ? element.name : "???";
    },
    defaultOrder: "startDate DESC"
  };

  angular.extend(that, LbService.getModelBis(params));

  that.getCourseTypes = [{
    value: "C1",
    name: "Atelier Simulateur"
  }, {
    value: "C2",
    name: "Atelier Théorie"
  }, {
    value: "C3",
    name: "Ateliers multiples"
  }, {
    value: "C4",
    name: "Conduite sur route"
  }, {
    value: "C5",
    name: "Coaching individuel"
  }, {
    value: "other",
    name: "Autre"
  }];

  that.getCourseTypes = ["Atelier Simulateur", "Atelier Théorie", "Ateliers multiples", "Conduite sur route", "Coaching individuel", "Autre"];
}]);