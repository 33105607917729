"use strict";

(function () {
  "use strict";

  Ctrl.$inject = ["vm", "moment"];
  angular.module("customers").controller("CustomersCtrl.export", Ctrl);

  function Ctrl(vm, moment) {
    vm.export = function () {
      console.log("in export");

      var convertDateWithTz = function convertDateWithTz(oneDate) {
        return new Date(moment.tz(oneDate, "Europe/Paris").format("YYYY-MM-DDTHH:mm:ss") + ".000Z");
      };

      var data = [];
      var rowsHeight = [{ hpt: 15 }]; // first row
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = vm.rawActiveCustomer[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var customer = _step.value;

          var max = 1;
          try {
            if (customer.insurers && customer.insurers.length > max) {
              max = customer.insurers.length;
            }
            if (customer.lead && customer.lead.length > max) {
              max = customer.lead.length;
            }
            if (customer.drmResp && customer.drmResp.length > max) {
              max = customer.drmResp.length;
            }
            if (customer.commResp && customer.commResp.length > max) {
              max = customer.commResp.length;
            }
            if (customer.copilMembers && customer.copilMembers.length > max) {
              max = customer.copilMembers.length;
            }

            var row = {
              "Nom du client": customer.name,
              "Chargé(e) de clientèle": customer.accountManagerName,
              "Assureurs/Courtiers": customer.insurers ? customer.insurers.join("\n") : "",
              LEAD: customer.lead ? customer.lead.join("\n") : "",
              "Email du LEAD": customer.leadMail ? customer.leadMail.join("\n") : "",
              "Responsable DRM": customer.drmResp ? customer.drmResp.join("\n") : "",
              "Email du responsable DRM": customer.drmRespMail ? customer.drmRespMail.join("\n") : "",
              "Correspondant communication": customer.commResp ? customer.commResp.join("\n") : "",
              "Email du correspondant communication": customer.commRespMail ? customer.commRespMail.join("\n") : "",
              "Membres du COPIL": customer.copilMembers ? customer.copilMembers.join("\n") : "",
              "Mails des membres du COPIL": customer.copilMembersMails ? customer.copilMembersMails.join("\n") : "",
              "Courtier/Assureur à inviter au COPIL": customer.infoWhoInCopilInsurer,
              "Type de flotte": customer.fleetTypeNames ? customer.fleetTypeNames.join("\n") : "",
              "Type de roulants": customer.driverTypeNames ? customer.driverTypeNames.join("\n") : "",
              "Date de début de contrat": customer.startDate ? convertDateWithTz(customer.startDate) : "",
              "Date de démarrage des DEPA": customer.depaStartDate ? convertDateWithTz(customer.depaStartDate) : "",
              "Date de fin de contrat initiale": customer.initialEndDate ? convertDateWithTz(customer.initialEndDate) : "",
              "Date de fin/renouvellement de contrat": customer.endDate ? convertDateWithTz(customer.endDate) : "",
              "Logiciel synchronisé": customer.apiSyncName,
              "Date du précédent COPIL": customer.lastCopilDate ? convertDateWithTz(customer.lastCopilDate) : "",
              "Date du prochain COPIL": customer.nextCopilDate ? convertDateWithTz(customer.nextCopilDate) : ""
            };
            data.push(row);

            rowsHeight.push({ hpt: 15 + 12 * (max - 1) });
          } catch (error) {
            console.log("customer export error", customer, error);
          }
        }

        /* generate a worksheet */
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var ws = XLSX.utils.json_to_sheet(data);

      var columnsWidth = [];

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = Object.keys(data[0])[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var key = _step2.value;

          columnsWidth.push({ wch: key.length + 2 });
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      ws["!cols"] = columnsWidth;
      ws["!rows"] = rowsHeight;

      /* add to workbook */
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Clients");

      /* write workbook (use type 'binary') */
      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      /* generate a download */
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) {
          view[i] = s.charCodeAt(i) & 0xff;
        }return buf;
      }

      saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), "export clients.xlsx");
    };
  }
})();