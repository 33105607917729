"use strict";

/**
 * Created by sguilly on 04/10/16.
 */
(function () {
  "use strict";

  /* @ngdoc object
   * @name pages
   * @description
   *
   */

  angular.module("pages", ["ui.router", "FileManagerApp", "models", "formly", "formlyBootstrap", "ngInputCurrency", "common", "customers", "users", "roles", "groups", "vehicles", "depa", "courses", "login", "damages", "schedules", "home", "actionHistorys", "files", "customerCommunications", "insurerContracts", "documentRequests", "customIframe", "tutorialModal"]);
})();