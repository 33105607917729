"use strict";

(function () {
  "use strict";

  /* @ngdoc object
   * @name customers
   * @description
   *
   */

  angular.module("customers", ["ui.router", "common", "customers.selectionCustomers"]);
})();